import React, { Component, Suspense } from 'react';
import styles from './styles/HomePage.module.css';
import { inject, observer } from 'mobx-react';
import { action, toJS } from 'mobx';
import Login from '../components/Login';
import { withRouter } from 'react-router-dom';

import LoginCheck from '../components/LoginCheck';
import Header from '../components/Header';
import Home from './Home'
import Menus from './Menus';
import Notifications from './Notifications'
import Contact from './Contact'
import Setting from './Setting';
import Info from './Info';
import UserPage from './UserPage';
import AdminLogin from './AdminLogin';
import EditItemPopup from '../components/EditItemPopup/EditItemPopup';
import EditElergenimPopup from '../components/EditItemPopup/EditElergenimPopup';
import EditInfoPopup from '../components/EditItemPopup/EditInfoPopup';
import EditNotificationPopup from '../components/EditItemPopup/EditNotificationPopup';

const DiningRoom = React.lazy(() => import('./DiningRoom'));
const DiningRoom13 = React.lazy(() => import('./DiningRoom13'));
const Hadran = React.lazy(() => import('./Hadran'));
const Foodcourt = React.lazy(() => import('./Foodcourt'));
const Stekiya = React.lazy(() => import('./Stekiya'));
const Salad = React.lazy(() => import('./Salad'));
const Pasta5100 = React.lazy(() => import('./Pasta5100'));
const CoffeShop = React.lazy(() => import('./CoffeShop'));
const GlotenFree = React.lazy(() => import('./GlotenFree'));
const Mehadrin = React.lazy(() => import('./Mehadrin'));
const Elergenim = React.lazy(() => import('./Elergenim'));
const SearchResults = React.lazy(() => import('./SearchResults'));
const MyLikes = React.lazy(() => import('./MyLikes'));
const ExcelUpdate = React.lazy(() => import('./ExcelUpdate'));


@inject("login", "links", "pages")
@observer
class MainScreen extends Component {

    constructor() {
        super();
        this.state = {
            isMobile: window.innerWidth < 767,
            EnterButtonClicked: false,
            ShowMainScreen: false,
            SelectedPage: 'home',
            PrevArr: ['home'],
            ShowPrevArrow: false,
            HeaderPadding: '130px',
            HeaderHeight: '100px',
            switch: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);

        const url = new URL(window.location);
        const page = url.searchParams.get('page');
        if (page == 'dining-room') {
            this.setState({
                ShowMainScreen: true
            })
            this.SetPage('DiningRoom')
        }
    }


    updateIsMobile = () => {
        this.setState({
            isMobile: window.innerWidth < 767
        });
    }


    LoginButtonClicked = (e) => {
        console.log(e)
    }

    clearUrl = () => {
        const url = new URL(window.location);
        url.searchParams.delete('id');
        window.history.pushState({}, '', url);
        url.searchParams.delete('page');
        window.history.pushState({}, '', url);
    }

    EnterButtonClicked = () => {
        this.setState({ EnterButtonClicked: true })
        setTimeout(() => {
            this.setState({ ShowMainScreen: true })
        }, 500);
    }

    @action
    GotoPrev = () => {
        this.clearUrl()
        let PrevArr = [...this.state.PrevArr]
        // console.log(PrevArr)
        PrevArr.splice(-1)//RETURN LAST ITEM AND REMOVE IT FROM ARRAY
        const lastItem = PrevArr[PrevArr.length - 1]

        if (PrevArr.length == 1) {
            this.setState({
                ShowPrevArrow: false,
                SelectedPage: 'home',
                PrevArr: ['home']
            })
            return
        }
        this.setState({
            SelectedPage: lastItem,
            PrevArr: PrevArr
        })

    }

    @action
    SetPage = (e) => {

        let SelectedPage = this.state.SelectedPage

        if (SelectedPage == e) {
            return
        }

        let PrevArr = this.state.PrevArr
        PrevArr.push('SetPage')

        this.setState({
            ShowPrevArrow: true,
            SelectedPage: e,
            PrevArr: PrevArr
        })
        this.props.pages.SelectedPage = e
    }

    render() {
        let pageContent = <Login EnterButtonClicked={this.EnterButtonClicked.bind(this)} />
        const header = <Header HeaderHeight={this.state.HeaderHeight} ShowPrevArrow={this.state.ShowPrevArrow} SetPage={this.SetPage.bind(this)} GotoPrev={this.GotoPrev.bind(this)} />

        if (this.state.ShowMainScreen) {
            let SelectedPage = this.state.SelectedPage
            switch (SelectedPage) {
                case 'home':
                    pageContent =
                        <>
                            {header}
                            <Home
                                PaddingTop={this.state.HeaderPadding}
                                SeeAll={this.SetPage.bind(this, 'menus')}
                                SetPage={this.SetPage.bind(this)}
                            />
                        </>
                    break
                case 'menus':
                    pageContent =
                        <>
                            {header}
                            <Menus
                                PaddingTop={this.state.HeaderPadding}
                                SetPage={this.SetPage.bind(this)}
                            />
                        </>
                    break
                case 'notifications':
                    pageContent =
                        <>
                            {header}
                            <Notifications PaddingTop={this.state.HeaderPadding} />
                        </>
                    break
                case 'contact':
                    pageContent =
                        <>
                            {header}
                            <Contact PaddingTop={this.state.HeaderPadding} />
                        </>
                    break
                case 'setting':
                    pageContent =
                        <>
                            {header}
                            <Setting />
                        </>
                    break
                case 'info':
                    pageContent =
                        <>
                            {header}
                            <Info PaddingTop={this.state.HeaderPadding} />
                        </>
                    break
                case 'user_page':
                    pageContent =
                        <>
                            {header}
                            <UserPage
                                PaddingTop={this.state.HeaderPadding}
                                SetPage={this.SetPage.bind(this)}
                            />
                        </>
                    break
                case 'login':
                    pageContent =
                        <>
                            {header}
                            <AdminLogin LoginButtonClicked={this.LoginButtonClicked.bind(this)} SetPage={this.SetPage.bind(this)} />
                        </>
                    break

                case 'DiningRoom':
                    this.props.pages.CloseDatePageId = 7423
                    this.props.pages.SlectedCatId = 109
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <DiningRoom PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'dining_Room_13':
                    this.props.pages.CloseDatePageId = 7425
                    this.props.pages.SlectedCatId = 110
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <DiningRoom13 PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                case 'hadran':
                    this.props.pages.CloseDatePageId = 8535
                    this.props.pages.SlectedCatId = 117
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Hadran PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                case 'foodcourt':
                    this.props.pages.CloseDatePageId = 7428
                    this.props.pages.SlectedCatId = 111
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Foodcourt PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'steak':
                    this.props.pages.CloseDatePageId = 7430
                    this.props.pages.SlectedCatId = 112
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Stekiya PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'salad':
                    this.props.pages.CloseDatePageId = 7510
                    this.props.pages.SlectedCatId = 113
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Salad PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'pasta':
                    this.props.pages.CloseDatePageId = 7512
                    this.props.pages.SlectedCatId = 114
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Pasta5100 PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'coffe_shop':
                    this.props.pages.CloseDatePageId = 7512
                    this.props.pages.SlectedCatId = 115
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <CoffeShop PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'gluten_free':
                    this.props.pages.SlectedCatId = 118
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <GlotenFree PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'mehadrin':
                    this.props.pages.SlectedCatId = 119
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Mehadrin PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break

                case 'elergenim':
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <Elergenim PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                case 'likes':
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <MyLikes PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                case 'search':
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <SearchResults PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                case 'from_file':
                    this.props.pages.ClearAllExcelUpdate()
                    pageContent =
                        <>
                            {header}
                            <Suspense fallback={<></>}>
                                <ExcelUpdate PaddingTop={this.state.HeaderPadding} />
                            </Suspense>
                        </>
                    break
                default:
                    pageContent =
                        <>
                            {header}
                            <Home
                                PaddingTop={this.state.HeaderPadding}
                                SeeAll={this.SetPage.bind(this, 'menus')}
                                SetPage={this.SetPage.bind(this)}
                            />
                        </>
            }

        }

        return (
            <LoginCheck loggedin={true}>
                <EditItemPopup />
                <EditElergenimPopup />
                <EditInfoPopup />
                <EditNotificationPopup />
                {pageContent}
            </LoginCheck>
        );


    }
}
export default withRouter(MainScreen);


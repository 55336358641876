import React, { Component, Suspense } from 'react';
import styles from './styles/Home.module.css';
import { inject, observer } from 'mobx-react';
import SearchInput from '../components/muiMaterial/SearchInput';
import NotificationsSlider from '../components/Notifications/NotificationsSlider';
import LoginCheck from '../components/LoginCheck';
import DiningRoomsSlider from '../components/DiningRooms/DiningRoomsSlider';
import WeekFavourites from '../components/Favourites/WeekFavourites';

@inject("login", "links", "pages")
@observer
class Home extends Component {

    constructor() {
        super();
        this.state = {
            SearchInput: '',
            isMobile: window.innerWidth < 767,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }


    updateIsMobile = () => {

    }

    SliderItemClick = (e) => {
        this.props.SetPage(e)
    }

    onChangeSearchInput = (e) => {
        const Name = e.target.name
        const Value = e.target.value
        this.setState({
            [Name]: Value
        })
    }

    Search = () => {
        const SearchInput = this.state.SearchInput
        if (SearchInput !== '') {
            this.props.SetPage('search')
            this.props.pages.SearchResults = this.state.SearchInput
        }
    }

    render() {
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            arrows: false
        };

        return (
            <LoginCheck loggedin={true}>

                <div className={styles.MainContainer} style={{ paddingTop: this.props.PaddingTop }}>
                    <SearchInput
                        value={this.state.SearchInput}
                        name={"SearchInput"}
                        onChange={this.onChangeSearchInput.bind(this)}
                        onClick={this.Search.bind(this)}
                    />
                    <NotificationsSlider SliderItemClick={this.SliderItemClick.bind(this)} />
                    <DiningRoomsSlider SliderItemClick={this.SliderItemClick.bind(this)} SeeAll={this.props.SeeAll.bind(this)} />
                    <WeekFavourites />
                </div>


            </LoginCheck>
        );


    }
}
export default Home;


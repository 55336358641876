import React, { Suspense, lazy } from 'react';
import { StyleRoot } from 'radium';
import { Switch, Route } from "react-router-dom";

// import Header from './components/header/Header';
// import Fotter from './components/footer/Fotter';
import MainScreen from './screens/MainScreen';
import clientConfig from '../src/client-config';

// const About = React.lazy(() => import('./screens/About'));
// const UrbanRenewal = React.lazy(() => import('./screens/UrbanRenewal'));
// const Contact = React.lazy(() => import('./screens/Contact'));
// const ProjectsScreen = React.lazy(() => import('./screens/ProjectsScreen'));
// const SingleProjectScreen = React.lazy(() => import('./screens/SingleProjectScreen'));
// const Media = React.lazy(() => import('./screens/Media'));
// const AccessibilityStatement = React.lazy(() => import('./screens/AccessibilityStatement'));

import CroneJobTest from './screens/CroneJobTest';

import './App.scss';
import './App_en.scss';
import './App_he.scss';
import './JsComposer.scss';
import '../src/assets/css/js_composer.min.css';
import './CustomBootStrap.scss'

function App() {
  return (
    <StyleRoot>

      <div className="App">


        <Switch>
          {/* <Route path="/הצהרת-נגישות">
            <Suspense fallback={<></>}>
              <AccessibilityStatement />
            </Suspense>
          </Route> */}

          <Route path="/cron_job_test">
            <CroneJobTest />
          </Route>

          <Route path="/" forceRefresh={true}>  <MainScreen />  </Route>

        </Switch>



      </div>
    </StyleRoot>
  );
}

export default App;

import { observable, configure, runInAction, action, makeAutoObservable } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import { enableStaticRendering } from "mobx-react"

enableStaticRendering(typeof window === 'undefined')
configure({ enforceActions: true });

class Links {
    @observable loading = false
    @observable style_link = clientConfig.siteUrl + '/wp-content/react_js_stylesheets/client_style.css?v' + Math.random()

    @observable FoodPattern = clientConfig.siteUrl + '/wp-content/react_js_images/food_pattern.svg'
    @observable LogoMenu = clientConfig.siteUrl + '/wp-content/react_js_images/logo_menu.png'
    @observable Spinner = clientConfig.siteUrl + '/wp-content/react_js_images/spinner.png'
    @observable Star = clientConfig.siteUrl + '/wp-content/react_js_images/star.svg'
    @observable IconHome = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_home.svg'
    @observable IconBookOpen = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_book_open.svg'
    @observable IconBell = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_bell.svg'
    @observable IconPhone = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_phone.svg'
    @observable IconSetting = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_setting.svg'
    @observable IconInfo = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_info.svg'
    @observable IconUser = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_user.svg'
    @observable IconLogin = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_login.svg'
    @observable IconLogout = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_logout.svg'
    @observable IconExcel = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_excel.svg'
    @observable Xnav = clientConfig.siteUrl + '/wp-content/react_js_images/icons/x_nav.svg'
    @observable IconSearch = clientConfig.siteUrl + '/wp-content/react_js_images/icons/icon_search.svg'
    @observable NotificationBack = clientConfig.siteUrl + '/wp-content/react_js_images/notification_back.png'
    @observable CloseSing = clientConfig.siteUrl + '/wp-content/react_js_images/close_sing.png'
    @observable MaitennceSing = clientConfig.siteUrl + '/wp-content/react_js_images/maitennce_sing.png'

  

    @observable oHashiLogo = clientConfig.siteUrl + '/wp-content/react_js_images/o_hashi_logo.png'
    @observable StekiyaLogo = clientConfig.siteUrl + '/wp-content/react_js_images/stekiya_logo.png'
    @observable AdisonLogo = clientConfig.siteUrl + '/wp-content/react_js_images/adison_logo.png'
    @observable EdisonLogoWhite = clientConfig.siteUrl + '/wp-content/react_js_images/edison_logo_white.png'

    //NOTIFICATIONS ICONS
    @observable IconApron = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_apron.svg'
    @observable IconApronWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_apron_white.svg'
    @observable IconFoodCover = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_food_cover.svg'
    @observable IconFoodCoverWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_food_cover_white.svg'
    @observable IconHat = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_hat.svg'
    @observable IconHatWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_hat_white.svg'
    @observable IconPlate = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_plate.svg'
    @observable IconPlateWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_plate_white.svg'
    @observable IconSalting = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_salting.svg'
    @observable IconSaltingWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_salting_white.svg'
    @observable IconSilvers = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_silvers.svg'
    @observable IconSilversWhite = clientConfig.siteUrl + '/wp-content/react_js_images/icons/notification_icons/icon_silvers_white.svg'

    @observable heartAnimation = clientConfig.siteUrl + '/wp-content/react_js_images/heart_animation.gif'
    @observable heartOutline = clientConfig.siteUrl + '/wp-content/react_js_images/heart_outline.png'
    @observable heartRed = clientConfig.siteUrl + '/wp-content/react_js_images/heart_red.png'

    //Sub Catecories ICONS
    @observable IconPita = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/pita.png'
    @observable IconFreeDrinks = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/free_drinks.png'
    @observable IconShef = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/shef.png'
    @observable IconSoup = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/soup.png'
    @observable IconFish = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/fish.png'
    @observable IconMeat = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/meat.png'
    @observable IconVeg = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/veg.png'
    @observable IconExtras = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/extras.png'
    @observable IconSalad = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/salad.png'
    @observable IconDesserts = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/desserts.png'
    @observable IconFirstDish = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/first_dish.png'
    @observable IconPastaTypes = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/pasta_types.png'
    @observable IconSauce = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/sauce.png'
    @observable IconSauce2 = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/sauce2.png'
 
    @observable IconvVegtables = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/vegtables.png'
    @observable IconVegtables2 = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/vegtables2.png'
    @observable IconPastaMeat = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/pasta_furi_meat.png'
    @observable IconHummus = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/hummus.png'
    @observable IconChips = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/chips.png'
    @observable IconSmileShef = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/smile_shef.png'
    @observable IconCoffe = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/coffe.png'
    @observable IconHamburger = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/hamburger.png'
    @observable IconGrill = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/grill.png'
    @observable IconSir = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/sir.png'
    @observable IconCake = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/cake.png'
    @observable IconBowl = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/bowl.png'
    @observable IconBowl2 = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/bowl2.png'
    @observable IconKimchi = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/kimchi.png'
    @observable IconAssianDisert = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/assian_disert.png'
    @observable IconLafa = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/lafa.png'
    @observable IconLafaAndSalad = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/lafa_and_salad.png'
    @observable IconBottles = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/bottles.png'
    @observable IconFish2 = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/fish.png'
    @observable IconEgg = clientConfig.siteUrl + '/wp-content/react_js_images/icons/sub_cats/egg.png'
    
    @observable FioriLogo = clientConfig.siteUrl + '/wp-content/react_js_images/fiori.png'

    @observable ElergenimImage = clientConfig.siteUrl + '/wp-content/react_js_images/elergenim_image.png'
    @observable Sun = clientConfig.siteUrl + '/wp-content/react_js_images/sun.png'

    @observable JoeLogo = clientConfig.siteUrl + '/wp-content/react_js_images/joe_logo.png'
    @observable coffeDealIcon = clientConfig.siteUrl + '/wp-content/react_js_images/icons/joe/coffe_deal_icon.png'
    @observable BussinesMeal = clientConfig.siteUrl + '/wp-content/react_js_images/icons/joe/bussines_meal.png'
    @observable HotDrink = clientConfig.siteUrl + '/wp-content/react_js_images/icons/joe/hot_drink.png'

    

    //Loading Gifs
    @observable DinningRoomGif = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/dinning_room.gif'
    @observable Noodles = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/Noodles.gif'
    @observable Hamburger = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/hamburger.gif'
    @observable Woman = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/woman.gif'
    @observable Pan = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/pan.gif'
    @observable SpinnerAnimation = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/spinner.gif'
    @observable SpinnerAnimation2 = clientConfig.siteUrl + '/wp-content/react_js_images/gifs/spinner2.gif'

    @observable XlsxDinningRoom = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט חדר אוכל.xlsx?v' + Math.random()
    @observable XlsxHadran = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט הדרן.xlsx?v' + Math.random()
    @observable XlsxFoodcourt = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט פודקורט.xlsx?v' + Math.random()
    @observable XlsxStekiya = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט סטקייה.xlsx?v' + Math.random()
    @observable XlsxCoffeShop = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט בית קפה.xlsx?v' + Math.random()
    @observable XlsxSalad = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט סלט בהרכבה.xlsx?v' + Math.random()
    @observable XlsxGlutenfree = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט ללא גלוטן.xlsx?v' + Math.random()
    @observable XlsxMhadrin = clientConfig.siteUrl + '/wp-content/react_js_images/תפריט כשרות מהודרת.xlsx?v' + Math.random()

 

    constructor() {
        makeAutoObservable(this);
    };

    @action
    GetSiteSetting() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.PageId = '50'
        this.loading = true
        this.GetSettingLoading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.PageId}?skip_cache=1`)
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR: ' + err)
                });
            })
    };






};



export default new Links();
